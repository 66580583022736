/* Spinner */
.content-loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    left: 0;
    background: #84848487;

    background: -webkit-gradient(linear,left top,right top,color-stop(25%,hsl(0deg 0% 75% / 40%)),color-stop(37%,hsl(0deg 0% 51% / 40%)),color-stop(63%,hsl(0deg 0% 75% / 40%)));
    background-size: 400% 100%;
    -webkit-animation: ant-skeleton-loading 1.2s ease infinite;
}

#animal-loader {
    top: 40%;
    left: 50%;
    opacity: 1;
    position: fixed;
    width: 160px;
    height: 160px;
    background-size: contain !important;
    bottom: 0;
    z-index: 1050;
    transform: translateX(-50%);
}

.blue-cat-loader {
    background: url(../images/bluecat.gif) no-repeat center;
}

.cutie-fox-loader {
    background: url(../images/cutiefox.gif) no-repeat center;
}

.kaki-dog-loader {
    background: url(../images/kakidog.gif) no-repeat center;
}

.pink-pig-loader {
    background: url(../images/pinkpig.gif) no-repeat center;
}

/* End Spinner */