@import './variables.module.scss';
@import "../../styles/css/vars.scss";


// .des-content .ant-collapse {
//   border-radius: 10px !important;
// }

.iconPanel{
    font-size: 18px;
    color: $primary-color;
    margin-right: 8px;

}
.wrapTitle{
    display: flex;
    align-items: center;
}
.ant-collapse-content-box {
    padding-top: 0 !important;
  }
  .ant-collapse-header {
    border: 1px solid #dfe6ec;
    // border-radius: 4px 4px 0px 0px;
    .text {
      font-weight: bold;
    }
  }
.iconItem{
    background: #f7f9fc;
    width: 30px;
    height: 30px;
    min-width: auto;
    margin-right :10px;
    border-radius: 2px;
}
.buttonOk{
  background-color: $primary-color !important;
  color: white !important;
}
.footerAction{
  height: 60px;
  width: 100vw;
  position: fixed;
  left: 0;right: 0;
  bottom: 0;
  box-shadow: 0 -4px 8px rgba(0,0,0,.1);
  

}
.actionRight{
  background-color:white ;
  color: $main_color;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 48px;
  cursor: pointer;

}
.actionLeft {
  color: white;
  font-weight: bold;
  background-color: $main_color;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  cursor: pointer;

}
.role-setting {
    .btn-action {
      min-width: unset;
    }
  
    button[disabled] {
      min-width: unset;
    }
  
    span.link-name {
      color: $black;
      font-size: 16px;
    }
  
    span.link-name:hover {
      color: $black;
      text-decoration: underline;
      cursor: pointer;
    }
  
    .ant-collapse-content-box {
      padding: 0px;
    }
  
    .ant-collapse
      > .ant-collapse-item.ant-collapse-no-arrow
      > .ant-collapse-header {
      padding: 0px;
    }
  
    .ant-collapse-ghost
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box {
      padding: 0px;
    }
  }
  
  .role-setting-popover .ant-popover-inner-content {
    padding: 12px 16px !important;
  }
  
  .first-icon {
    vertical-align: middle;
    color: $main_color;
    margin-right: 6px;
  }
  
  .ant-popover-content {
    box-shadow: -1px -3px 3px rgba(0, 0, 0, 0.3), 1px 3px 3px rgba(0, 0, 0, 0.3);
  }
  
  .btn-add-field {
    color: #000000d9;
    border: 1px dashed #d9d9d9;
    background: #fff;
  }
  
  .form-role-setting .ant-form-item-label > label {
    font-weight: bold;
  }
.itemSearch{
    width: 100%;
    min-height: 100px;
    border-radius: 2px;
    position: relative;    
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-top: 10px;
    border-left:2px solid #0080CB ;
    // display: flex;
    // gap: 5px;
    // flex-direction: column;
    padding: 10px;
    .iconActionEs{
    //  position: absolute;
    //  top:10px;
    //  right: 0px;
    //  display: flex;
    //  justify-content: center;
    //  align-items: center;
     
    }
    p{
      padding: 0;
      margin: 0;
      margin-top: 3px;
    }
    .idText{
        color: #0080CB;
     }
     .contentText{
        font-weight: bold;
        // width: 100%;
        overflow-wrap: break-word;
        text-align: left;
        margin-left: 0 !important;
        // text-align: flex-start;
     }
}
.ant-collapse{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
//   border-radius: 0px !important;
  border: none !important;
}
 .ant-collapse-header {
    // border-radius: 4px 4px 0px 0px !important;
    border: none !important;
}
.ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 16px !important;
}
.actionCancel {
  color: red;
  font-weight: bold;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  cursor: pointer;
}
.wrapStep2,.viewEstimate{
  .ant-collapse-content > .ant-collapse-content-box{
    padding: 0 !important;
  }
}
.modalAddEstimate .ant-modal-header,.ant-modal-body{
  padding: 8px;
  padding-right: 16px;
}
.machine-content .ant-modal-body{
  padding: 8px;
  padding-right: 8px;
}

.collapseCost.ant-collapse > .ant-collapse-item > .ant-collapse-header { 
  padding-left: 25px;
  
}
// .collapseCost {
  
//  box-shadow: none;
// }
.collapseCost.ant-collapse{
  box-shadow: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-right: 8px !important;
}
.anticon.anticon-edit{
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapTableCost{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
border-radius: 4px;
padding: 8px;
padding-top: 0;
}

.buttonExport{
    border: 1px solid #0080cb;
    padding: 13px;
    background: #fff;
    color: #0080cb;
    font-size: 18px;
    border-radius: 2px;
    width: 100%;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-bottom: 10px;
  }
  .drawerSort .ant-drawer-body{
    // background-color: red !important;
    padding: 5px;
  }
//   .drawEstimate {
//     -ms-flex-positive: 1;
//     flex-grow: 1;
//     padding: 24px !important;
//     overflow: auto;
//     font-size: 14px;
//     line-height: 1.5715;
//     word-wrap: break-word;
//     display: flex !important;
//     justify-content: center !important;
//     flex-direction: column !important;
// }
.itemVersion{
  cursor: pointer;
  padding: 0 5px;
  width: 100%;
  margin-bottom: 3px;
}
.itemVersion:hover{
  background-color: lightgray;
}
.saveNormalEstimate{
  background: #0080cb !important;
    color: #fff !important;
}
.step3 .collapseCost.ant-collapse  {
  .ant-collapse-item > .ant-collapse-header{
    padding-left: 10px !important;

  }
  .ant-collapse-expand-icon {
    height: 22px !important;
    display: flex !important;
    align-items: center !important;
    padding-inline-end: 3px !important;
    margin-inline-start: -6px !important;
}
}
.valueInputRight .ant-input,.ant-select-selector {
  text-align: right;
}
.modalOptionMoney .ant-modal-content {
  padding: 8px !important;
}
.modalOptionMoney .ant-modal-body {
 
  padding: 0 !important;
}
.textNote{
  height: 108px !important;
}
.textNote2{
  min-height: 72px !important;
}
.modalImage .ant-modal-body{
  padding: 0px !important;
  background-color: "red";
}
.selectVersion {
  .ant-select-selector{
    background-color: #0080cb !important;
    color: white !important;
    }
}

.item-modal-no-border{
  border-color: transparent !important;
}