@import "../../styles/css/vars.scss";

.role-setting {
  
  .ant-collapse {
    border: none !important;
    box-shadow: none !important;
  }
  h3{
    margin: 0 !important;
  }
  
  
  .ant-list-item-meta-avatar {
    margin-right: 6px;
  }

  .btn-action {
    min-width: unset;
  }

  button[disabled] {
    min-width: unset;
  }

  span.link-name {
    color: $black;
    font-size: 16px;
  }

  span.link-name:hover {
    color: $black;
    text-decoration: underline;
    cursor: pointer;
  }

 .ant-collapse-content-box {
    padding: 0px !important;
  }

  .ant-collapse
    > .ant-collapse-item.ant-collapse-no-arrow
    > .ant-collapse-header {
    padding: 0px;
  }

  .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 0px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-right: 12px;
  }
}

.role-setting-popover .ant-popover-inner-content {
  padding: 12px 16px !important;
}

.first-icon {
  vertical-align: middle;
  color: $main_color;
  margin-right: 6px;
}

.ant-popover-content {
  box-shadow: -1px -3px 3px rgba(0, 0, 0, 0.3), 1px 3px 3px rgba(0, 0, 0, 0.3);
}

.btn-add-field {
  color: #000000d9;
  border: 1px dashed #d9d9d9;
  background: #fff;
}

.form-role-setting .ant-form-item-label > label {
  font-weight: bold;
}
