@import "../../styles/css/vars.scss";

.footer {
  &-page {
    position: fixed;
    bottom: 0;
    left: 220px;
    z-index: 3;
    height: 48px;
    background-color: white;

    &-top {
      // margin: 50px;
      height: 48px;
      display: flex;
    }

    .col-border-left {
      border-left: 1px solid #dfe6ec;
      margin: -12px 0;
      padding: 0 40px !important;
    }
  }
}
