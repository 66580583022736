@import "../../styles/css/vars.scss";
.ant-layout-sider,
.ant-menu {
  // transition: 0.5s;
  z-index: 1000;
}
@include desktop {
  .ant-menu-root:not(.ant-menu-inline-collapsed) {
    max-height: calc(100vh - 234px) !important;
    overflow-y: auto;
  }
}
.customSubMenu {
  & > .ant-menu-submenu-title {
    height: 50px !important;
    line-height: 50px !important;
    margin: 0 !important;
    font-size: 14px;
    font-weight: 500;
    &:hover {
      background-color: $main_color;
    }
  }
  &.ant-menu-submenu-open > .ant-menu-submenu-title,
  &.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #fff;
    background-color: $main_color;
  }
  &--mobile {
    & > .ant-menu-submenu-title {
      height: 50px !important;
      line-height: 50px !important;
      margin: 0 !important;
      background: #232a3d;
      font-size: 14px;
      font-weight: 500;
    }
    &.ant-menu-submenu-selected > .ant-menu-submenu-title {
      color: #fff;
      background-color: $main_color;
    }
  }
}
.custom-drawer {
  z-index: 10000;
  .ant-drawer-body {
    padding: 0px;
    background-color: #dbe7ff;
  }
  .ant-drawer-footer {
    border-top-color: #dbe7ff;
    background-color: #dbe7ff;
  }
  &--select {
    z-index: 10001;
    .title {
      font-size: 20px;
      font-weight: 600;
    }
    .row-select {
      padding: 8px 0;
      &:nth-child(even) {
        background: #f7f9fc;
      }
      .icon {
        display: none;
      }
      .name-office {
        font-weight: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &--selected {
        .name-office {
          max-width: calc(100% - 20px);
          font-weight: bold;
        }
        .icon {
          display: block;
        }
      }
    }
    .ant-drawer-body {
      padding: 8px;
    }
  }
}
hr.menu-bottom-divider {
  border-top: 1px solid #2c344a;
  border-bottom: none;
  border-left: none;
  border-right: none;
  margin-top: 0px;
  margin-bottom: 0px;
  width: calc(100% - 20px);
}
.buttonIcon {
  padding: 0px 10px !important;
  font-size: 18px !important;
  height: auto !important;
  color: $main_color !important;
  width: 100%;
}
.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 16px !important;
}

.iconSideBar {
  height: 20px;
  width: 20px;
}

.copy-right {
  font-size: 10px;
  color: #a0a9b1;
  z-index: -1;
  position: absolute;
  bottom: 48px;
  padding: 13px;
  &__mobile {
    font-size: 10px;
    color: #a0a9b1;
  }
}
