@import "../../styles/css/vars.scss";

.titleCustomGroup {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 24px;
}

// css for loading-inline
.loading-inline {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 9px;
}

.loading-inline div {
  position: absolute;
  top: 0;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #7dd0ff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading-inline div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.loading-inline div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.loading-inline div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.loading-inline div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}
// end css for loading inline
.fullScreen {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10000;
  background-color: rgba(204, 200, 200, 0.7);
}
.loadingDesktop {
  position: absolute;
  top: 45%;
  left: 45%;
}
// css for notification sync data
.notify {
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  height: 22px;
  background-color: $text_color;
  justify-content: center;

  &--online {
    background-color: $green;
  }
}
.syncText {
  color: $text_color;
  text-align: center;
  display: flex;
  align-items: center;
  height: 34px;
  background-color: $web_bg;
  justify-content: center;
  font-size: 14px;
}
.warnText {
  color: $text_color;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: $second_color;
  justify-content: center;
  font-size: 14px;
}
.network-status {
  position: fixed;
  width: 100%;
  top: 53px;
  z-index: 999;
}
// end css for notification sync data

// css for notification
.ant-notification .ant-notification-notice {
  max-width: calc(100vw - 48px);
}
// end css for notification
