@import "../../styles/css/vars.scss";

@include mobile {
  .desktop {
    display: none;
  }
}

@include desktop {
  .mobile {
    display: none;
  }
}

// TAB Machine: START
.tabMachine {
  &.ant-collapse {
    // border-radius: 4px;
    background-color: transparent;
    border: none;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .itemOfMachine {
    &:not(:last-child) {
      border-bottom: 1px solid $color-border-3;
    }

    // margin-bottom: 16px;
    padding: 16px;

    .ant-card-body {
      padding: 16px 24px;
      min-height: 54px;
    }

    .ant-image {
      max-width: 150px;
      max-height: 150px;
      overflow: hidden;
    }
  }
  &.ant-collapse > .ant-collapse-item {
    margin-bottom: 8px;
    border: 1px solid #dfe6ec;
    border-radius: 4px;
  }
  .ant-collapse-arrow {
    margin: 4px 0;
  }
  .ant-collapse-content {
    border-radius: 0 0 4px 4px;
  }
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 16px;
  }
}
// TAB Machine: END

.detailPreCheckItem {
  .part:first-child {
    border: 1px solid #dfe6ec;
    border-top: 1px solid #dfe6ec;
  }
  .part {
    border: 1px solid #dfe6ec;
    border-top: 0px;
    padding: 16px;
  }
}

.des {
  a {
    color: $main_link;

    &:hover {
      color: $main_color-hover;
    }
  }
  .linkButton {
    color: $main_color;
  }
  .ant-btn.ant-input-search-button {
    min-width: 0 !important;
    width: 50px !important;
  }
}

.demo-loading-container {
  position: absolute;
  bottom: 100px;
  width: 100%;
  text-align: center;
}
.iconAction {
  cursor: pointer;
  // Default
  &:hover {
    path {
      color: $main_color;
      fill: $main_color;
    }
  }
  // Del
  &-delete {
    cursor: pointer;
    max-width: 16px;
    max-height: 16px;
    &:hover {
      path {
        color: $text-danger;
        fill: $text-danger;
      }
    }
  }
  // View
  &-view {
    cursor: pointer;

    &:hover {
      path {
        color: #3faaca;
        fill: #3faaca;
      }
    }
  }
  // Clone
  &-clone {
    cursor: pointer;

    &:hover {
      path {
        color: #3d81bf;
        fill: #3d81bf;
      }
    }
  }
  // Edit
  &-edit {
    cursor: pointer;

    &:hover {
      path {
        color: $second_color;
        fill: $second_color;
      }
    }
  }
}
.button-back {
  padding-left: 16px;
  &:hover {
    cursor: pointer;
  }
  .icon-back {
    padding-left: 0 !important;
  }
  .text {
    font-weight: bold;
  }
}
.showAll {
  margin: 25px 16px;
  margin-top: 0 !important;
  &__mobile {
    margin: 0;
    padding: 8px;
    padding-top: 0;
  }
  .card-detail {
    padding: 16px;
    padding-bottom: 2px;
    box-shadow: 0px 1px 3px rgba(0, 29, 46, 0.08);
    border-radius: 4px;
    background: #ffffff;
    min-height: 375px;
  }
}
// Input antd
.ant-input,
.ant-picker-input input,
.ant-btn,
.ant-input-affix-wrapper {
  border-radius: 4px;
}
// Checkbox antd
.ant-checkbox-inner {
  border-radius: 3.2px;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background: $main_color;
  border-color: $main_color;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background: #aabbca4d;
  border: 1px solid #dfe6ec;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #a0a9b1;
}

// back-to-mobile
.page-mobile {
  &-back {
    display: flex;
    align-items: center;
    font-weight: bold;

    &:before {
      content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuMjkyNzMgMy4yOTI2TDQuNTg2MDYgNy45OTk5NEw5LjI5MjczIDEyLjcwNzNMMTAuNzA3NCAxMS4yOTI2TDcuNDE0MDYgNy45OTk5NEwxMC43MDc0IDQuNzA3MjdMOS4yOTI3MyAzLjI5MjZaIiBmaWxsPSIjMjUyNzMzIi8+Cjwvc3ZnPgo=);
      width: 18px;
      height: 17px;
    }
  }
}

// POPUP
.popupP2 {
  .ant-modal-body {
    padding: 20px;
    // min-height: 300px;
    // height: calc(100vh - 200px);
    // max-height: 1000px;
    // overflow: auto;
  }

  // .ant-modal-footer {
  //   text-align: left;
  // }
  .scroll-grid {
    height: calc(100vh - 300px);
    min-height: 200px;
    overflow: auto;
    padding-right: 8px;
    overflow-x: hidden;
  }
}

.modal-calendar {
  .ant-modal-header {
    padding: 16px 14px;

    .ant-modal-title {
      text-align: center;
    }
  }

  .ant-modal-close {
    top: 4px;
    right: 4px;

    .ant-modal-close-x {
      line-height: unset;
      width: fit-content;
      height: fit-content;
    }
  }
}

// MENU
.menuBottom {
  &:not(.menuBottomAuto) .ant-drawer-content-wrapper {
    height: calc(100% - 54px) !important;
  }

  .ant-drawer-mask {
    background-color: #000000b5;
  }
  .ant-drawer-header {
    border: 0;
    padding: 17px 16px 0;
  }
  .ant-drawer-content {
    border-radius: 12px 12px 0px 0px;

    .ant-drawer-body {
      padding: 16px;

      .buttonPC {
        height: 48px;
      }
    }
  }
  .ant-drawer-title {
    font-size: 20px;
    font-weight: bold;
  }
  .scroll-grid {
    height: calc(100vh - 220px);
    min-height: 200px;
    overflow: auto;
    padding-right: 8px;
    overflow-x: hidden;
  }

  &-footer {
    height: 48px;
    position: absolute;
    bottom: 0;
    width: 100vw;
    left: 0;
    // box-shadow: 0px -1px 6px #001d2e14;
    box-shadow: 0px -1px 9px #001d2e21;

    &-btnCancel {
      height: 100%;
      color: $main_color;
      background: white;
      text-align: center;
    }

    &-btnConfirm {
      height: 100%;
      color: white;
      background-color: $main_color;
      text-align: center;
    }
  }
}

// Color
.color-danger {
  color: $text-danger !important;
}
.color-default {
  color: $main_color !important;
}

// Color - svg
.svg-danger {
  path {
    fill: $text-danger !important;
  }
}
.svg-default {
  path {
    fill: $main_color !important;
  }
}
.svg-white {
  path {
    fill: white !important;
  }
}

//
.listItem {
  padding: 24px;
  border-bottom: 1px #e2dede solid;
  background-color: white;
}
.checkList {
  margin: 25px 16px;
  margin-top: 0 !important;
  // min-height: 100vh;
  // padding-top: 24px;
  &__mobile {
    margin: 0;
    padding: $padding_content_mobile;
  }
  .listCheck {
    max-height: 350px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    // margin: 0 -8px !important;
    &__mobile::-webkit-scrollbar {
      display: none;
    }
    &__mobile {
      margin: -16px -16px 8px !important;
      padding: 0 8px;
    }
  }
  .card-detail {
    padding: $padding_content;
    padding-bottom: 2px;
    box-shadow: 0px 1px 3px rgba(0, 29, 46, 0.08);
    border-radius: 4px;
    background: #ffffff;
    min-height: 410px;
  }
}
.itemList {
  // margin: 0 4px;
  border: 1px solid #dfe6ec;
  background-color: #f7f9fc;
  border-radius: 4px;
  @include mobile {
    box-shadow: 0px 1px 3px #001d2e14;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

  &:not([disabled]):hover {
    cursor: pointer;
    box-shadow: 0 0 0px 2px #00a0fe4d;
  }
  .id {
    grid-area: id;
    color: #1394df;
    font-weight: normal;
    margin-bottom: 8px;
  }
  .title {
    // grid-area: title;
    color: #252733;
    font-weight: 600;
    height: 53px;
    font-size: 16px;
    margin-bottom: 16px;
  }
  .status {
    // grid-area: status;
    height: 24px;
    margin-bottom: 6px;
  }
  .created {
    // grid-area: footer;
    color: $info-more;
    font-size: 14px;
    font-weight: 400;
  }
  .view-all {
    color: #1394df;
    font-size: 14px;
  }
  &-row {
    height: 155px;
    padding: 16px;
  }
  &__bookMark {
    height: 100px;
    font-weight: bold;
    padding: 10px;
  }

  &__notBookMark,
  &__checkResult {
    height: 155px;
    padding: 16px;
    font-weight: bold;
  }

  //
  // &__grid {
  //   &__bookMark {
  //     display: grid;
  //     grid-template-rows: 22px 48px 24px 22px;
  //     grid-template-areas: "id" "title" "status" "footer";
  //   }

  //   &__notBookMark {
  //     display: grid;
  //     grid-template-rows: 69px 24px 22px;
  //     grid-template-areas: "title" "status" "footer";
  //   }
  // }

  &--desktop {
    height: 200px;
    margin: 0;
  }
}
.itemList-2 {
  height: 155px;
  padding: 8px 16px;
  border: 1px solid #dfe6ec;
  background-color: #f7f9fc;
  border-radius: 4px;
  @include mobile {
    box-shadow: 0px 1px 3px #001d2e14;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

  &:not([disabled]):hover {
    cursor: pointer;
    box-shadow: 0 0 0px 2px #00a0fe4d;
  }
  .id {
    grid-area: id;
    color: #1394df;
    font-weight: normal;
    margin-bottom: 0;
  }
  .title {
    // grid-area: title;
    color: #252733;
    font-weight: 700;
    height: 53px;
    font-size: 16px;
    margin-bottom: 8px;
  }
  .account {
    color: $info-more;
    margin-bottom: 4px;
  }
  .more {
    color: $info-more;
    align-items: center;
  }
  .status {
    // grid-area: status;
    height: 24px;
    margin-bottom: 6px;
  }
  .created {
    color: $info-more;
    // grid-area: footer;
    font-size: 14px;
    font-weight: 400;
  }

  &--desktop {
    height: 200px;
    margin: 0;
  }
  .textStatus {
    padding: 4px 5.5px;
    font-weight: bold;
  }
}
.custom-height-clType {
  height: 180px;
}
.info-more {
  color: $info-more;
}
.textStatus {
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 12px;
  width: min-content;
}
.titleText {
  font-size: 14px;
  color: #252733;
  font-weight: bold;
  line-height: 22px;
  padding: 20px 0;
  padding-left: 0;

  @include mobile {
    padding: 0px 0 8px;
  }
}
.titleText2 {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #252733;
  font-weight: bold;
  padding: 0 0 16px;
  @include mobile {
    padding: 0px 0 8px;
  }

  svg {
    margin-right: 5px;
  }
}
.titleMachine {
  background: $bg_machine;

  // Default
  &_info {
    background: $bg_machine;
  }

  &_error {
    background: #ffe4e4;
  }
  &_warning {
    background: #fffae8;
  }
  &_done {
    background: #e8fff7;
  }

  &_disabled {
    background: #e3e3e3;
  }

  //
  &--info {
    color: $main_color !important;
    path {
      fill: $main_color !important;
    }

    &:hover {
      color: $main_color-hover !important;
      path {
        fill: $main_color-hover !important;
      }
    }
  }
}
.clear-value-input {
  color: $main_color;
  path {
    fill: $main_color;
  }
}
.ellipse-mark {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 10px;

  &-default {
    display: none;
  }
  &-error {
    background: #d31515;
  }
  &-warning {
    background: #f16900;
  }
  &-done {
    background: #1fc42f;
  }
  &-disabled {
    background: #928d8d;
  }
}
.sttMachine {
  &-default {
    display: none;
  }
  &-error {
    color: #d31515;
  }
  &-warning {
    color: #f16900;
  }
  &-done {
    color: #1fc42f;
  }
  &-disabled {
    color: #928d8d;
  }
}
.checklistFilter {
  padding: 0 $padding_content;
  box-shadow: 0px 1px 3px rgba(0, 29, 46, 0.08);
  border-radius: 4px;
  background: #ffffff;
}

.setting-group {
  padding: 0 16px;
  .content,
  .content-mobile {
    margin-top: 24px;
    padding: $padding_content;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 29, 46, 0.08);
    border-radius: 4px;
  }

  .content-mobile {
    padding: 0;
    box-shadow: none;
  }
}
